import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  boardingPassDetails: [],
  bpAddGoogleWallet: "",
  bpAddAppleWallet: "",
  isLoading: false,
  error: "",
  googleError: "",
  appleError: ""
};
const fetchCheckinBoardingPassSlice = createSlice({
  name: "checkinBoardingPass",
  initialState,
  reducers: {
    checkinBoardingPassDataRequest: state => {
      state.isLoading = true;
    },
    getCheckinBoardingPassSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.boardingPassDetails = action.payload.data;
    },
    getCheckinBoardingPassFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload;
      state.boardingPassDetails = [];
    },
    googleWalletBpRequest: state => {
      state.isLoading = true;
    },
    googleWalletBpSuccess: (state, action) => {
      state.isLoading = false;
      state.googleError = "";
      state.bpAddGoogleWallet = action.payload.data;
    },
    googleWalletBpFailure: (state, action) => {
      state.isLoading = false;
      state.googleError = action.payload;
      state.bpAddGoogleWallet = [];
    },
    appleWalletBpRequest: state => {
      state.isLoading = true;
    },
    appleWalletBpSuccess: (state, action) => {
      state.isLoading = false;
      state.appleError = "";
      state.bpAddAppleWallet = action.payload.data;
    },
    appleWalletBpFailure: (state, action) => {
      state.isLoading = false;
      state.appleError = action.payload;
      state.bpAddAppleWallet = [];
    }
  }
});
export const {
  checkinBoardingPassDataRequest,
  getCheckinBoardingPassSuccess,
  getCheckinBoardingPassFailure,
  googleWalletBpRequest,
  googleWalletBpSuccess,
  googleWalletBpFailure,
  appleWalletBpRequest,
  appleWalletBpSuccess,
  appleWalletBpFailure
} = fetchCheckinBoardingPassSlice.actions;
export default fetchCheckinBoardingPassSlice.reducer;