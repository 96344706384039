import { createSlice } from "@reduxjs/toolkit";
// import interlineJson from "../../../../../../../interlineNotCheckedin.json";
// import multiCityJson from "../../../../../../../../Downloads/Multicity.json"

const initialState = {
  checkinDetails: {},
  isLoading: false,
  hasData: false,
  checkInSuccess: false,
  checkinPnr: {},
  selectedJourney: [],
  error: ""
};
const fetchCheckInSlice = createSlice({
  name: "checkin",
  initialState,
  reducers: {
    retrieveJourneyRequest: (state, action) => {
      state.isLoading = true;
      state.checkInSuccess = false;
      state.error = "";
    },
    retrieveJourneySuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.checkInSuccess = true;
      state.checkinDetails = action.payload;
    },
    retrieveJourneyFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload.response.data.errors;
      state.checkInSuccess = false;
      state.checkinDetails = {};
    },
    resetJourneyDetails: state => {
      state.isLoading = false;
      state.checkinDetails = {};
      state.checkInSuccess = false;
      state.hasData = false;
      state.error = "";
    },
    setPNRandLastName: (state, action) => {
      state.checkinPnr = action.payload;
    },
    resetPNRDetails: state => {
      state.checkinPnr = {};
    },
    updateJourneyResponse: (state, action) => {
      var _state$checkinDetails;
      state.checkinDetails = state === null || state === void 0 || (_state$checkinDetails = state.checkinDetails) === null || _state$checkinDetails === void 0 ? void 0 : _state$checkinDetails.map(item => {
        var _action$payload;
        return (item === null || item === void 0 ? void 0 : item.id) === (action === null || action === void 0 || (_action$payload = action.payload) === null || _action$payload === void 0 ? void 0 : _action$payload.id) ? {
          ...item,
          ...action.payload
        } : item;
      });
    }
  }
});
export const {
  retrieveJourneyRequest,
  retrieveJourneySuccess,
  retrieveJourneyFailure,
  resetJourneyDetails,
  resetPNRDetails,
  setPNRandLastName,
  updateJourneyResponse
} = fetchCheckInSlice.actions;
export default fetchCheckInSlice.reducer;