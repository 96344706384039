import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  selectPassengerInfo: {},
  isLoading: false,
  hasData: false,
  error: "",
  selectPassengerStatus: {
    status: false,
    message: ""
  },
  selectedPassengerData: {},
  editMode: false
};
const selectPassengerSlice = createSlice({
  name: "selectPassenger",
  initialState,
  reducers: {
    selectPassengerRequest: (state, action) => {
      state.isLoading = true;
    },
    selectPassengerSuccess: (state, action) => {
      var _action$payload;
      state.isLoading = false;
      state.hasData = true;
      state.error = "";
      state.selectPassengerInfo = action.payload;
      state.selectPassengerStatus.status = true;
      state.selectPassengerStatus.message = action === null || action === void 0 || (_action$payload = action.payload) === null || _action$payload === void 0 ? void 0 : _action$payload.message;
    },
    selectPassengerFailure: (state, action) => {
      var _action$payload2;
      state.isLoading = false;
      state.error = action === null || action === void 0 || (_action$payload2 = action.payload) === null || _action$payload2 === void 0 || (_action$payload2 = _action$payload2.response) === null || _action$payload2 === void 0 ? void 0 : _action$payload2.data;
      state.selectPassengerInfo = {};
      state.selectPassengerStatus.status = false;
      state.selectPassengerStatus.message = "";
    },
    resetRemovePassengers: state => {
      state.selectPassengerInfo = {};
      state.selectedPassengerData = {};
      state.isLoading = false;
      state.hasData = false;
      state.error = "";
      state.selectPassengerStatus.status = false;
      state.selectPassengerStatus.message = "";
    },
    setSelectPassengerData: (state, action) => {
      state.selectedPassengerData = action.payload;
    },
    enableEditMode: state => {
      state.editMode = true;
    },
    disableEditMode: state => {
      state.editMode = false;
    },
    generateOtpRefKey: (state, action) => {
      state.isLoading = true;
      state.codeStatus = "pending";
    },
    generateOtpRefKeySuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.error = "";
      state.OtpRefKeyInfo = action.payload;
      state.codeStatus = "success";
    },
    generateOtpRefKeyFailure: (state, action) => {
      var _action$payload3;
      state.isLoading = false;
      state.error = action === null || action === void 0 || (_action$payload3 = action.payload) === null || _action$payload3 === void 0 || (_action$payload3 = _action$payload3.response) === null || _action$payload3 === void 0 ? void 0 : _action$payload3.data;
      state.resendOtpInfo = {};
      state.OtpRefKeyInfo = {};
      state.addFFP = {};
      state.codeStatus = "failure";
    },
    generateResendOtp: (state, action) => {
      state.isLoading = true;
      state.codeStatus = "pending";
    },
    generateResendOtpSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.error = "";
      state.resendOtpInfo = action.payload;
      state.codeStatus = "success";
    },
    generateResendOtpFailure: (state, action) => {
      var _action$payload4;
      state.isLoading = false;
      state.error = action === null || action === void 0 || (_action$payload4 = action.payload) === null || _action$payload4 === void 0 || (_action$payload4 = _action$payload4.response) === null || _action$payload4 === void 0 ? void 0 : _action$payload4.data;
      state.resendOtpInfo = {};
      state.OtpRefKeyInfo = {};
      state.addFFP = {};
      state.codeStatus = "failure";
    },
    getSubmitOtpReq: (state, action) => {
      state.isLoading = true;
      state.codeStatus = "pending";
    },
    submitOtpSuccess: (state, action) => {
      state.isLoading = false;
      state.hasData = true;
      state.error = "";
      state.addFFP = action.payload;
      state.codeStatus = "success";
    },
    submitOtpFailure: (state, action) => {
      var _action$payload5;
      state.isLoading = false;
      state.error = action === null || action === void 0 || (_action$payload5 = action.payload) === null || _action$payload5 === void 0 || (_action$payload5 = _action$payload5.response) === null || _action$payload5 === void 0 ? void 0 : _action$payload5.data;
      state.addFFP = {};
      state.OtpRefKeyInfo = {};
      state.resendOtpInfo = {};
      state.codeStatus = "failure";
    },
    resetCheckinOTPData: state => {
      state.codeStatus = "pending";
      state.error = "";
      state.addFFP = {};
      state.OtpRefKeyInfo = {};
      state.resendOtpInfo = {};
      state.hasData = false;
    }
  }
});
export const {
  selectPassengerRequest,
  selectPassengerSuccess,
  selectPassengerFailure,
  resetRemovePassengers,
  setSelectPassengerData,
  enableEditMode,
  disableEditMode,
  generateOtpRefKey,
  generateOtpRefKeySuccess,
  generateOtpRefKeyFailure,
  generateResendOtp,
  generateResendOtpSuccess,
  generateResendOtpFailure,
  getSubmitOtpReq,
  submitOtpFailure,
  submitOtpSuccess,
  resetCheckinOTPData
} = selectPassengerSlice.actions;
export default selectPassengerSlice.reducer;